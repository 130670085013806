import request from "@/utils/request";

// 获取锡康码（苏康码）、14天轨迹图
export function userTrackRecordGetByProjectId(id: any) {
  return request({
    url: "/project/userTrackRecord/getByProjectId/" + id,
    method: "get",
  });
}

// 提交锡康码和14天轨迹图
export function userTrackRecordSave(arg: any) {
  return request({
    url: "/project/userTrackRecord/save",
    method: "post",
    data: arg,
  });
}

// 查询个人上报温度
export function userTemperatureRecordPage(arg: any) {
  return request({
    url: "/project/userTemperatureRecord/page",
    method: "post",
    data: arg,
  });
}

// 提交个人当天温度
export function userTemperatureRecordSave(arg: any) {
  return request({
    url: "/project/userTemperatureRecord/save",
    method: "post",
    data: arg,
  });
}

// 获取所有人员当日体温列表
export function userTemperatureRecordGetListByDate(arg: any) {
  return request({
    url: "/project/userTemperatureRecord/getListByDate",
    method: "post",
    data: arg,
  });
}

// 根据人员获取锡康码（苏康码）、14天轨迹图
export function userTrackRecordGetByUserId(args: any) {
  return request({
    url: "/project/userTrackRecord/getByUserId",
    method: "GET",
    params: args,
  });
}

// 根据人员获取体温列表
export function userTemperatureRecordGetListByUser(arg: any) {
  return request({
    url: "/project/userTemperatureRecord/getListByUser",
    method: "post",
    data: arg,
  });
}
