
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import { parseTime } from "@/utils";
import * as api from "@/api/epidemic";

@Options({})
export default class extends Vue {
  @Prop() private selectDate!: string;
  private value = "";
  private refreshing = false;
  private loading = false;
  private finished = false;
  private workerlist: any[] = [];
  private date: Date | string = "";

  currentDate() {
    return parseTime(this.date, "{m}月{d}日");
  }

  mounted() {
    this.date = new Date();
  }

  @Watch("value")
  onValueChanged(newValue: string, oldValue: string) {
    this.refreshing = true;
    this.onRefresh();
  }

  @Watch("selectDate")
  onselectDateChanged(newValue: string, oldValue: string) {
    this.date = newValue;
    this.refreshing = true;
    this.onRefresh();
  }

  selectTime() {
    this.$emit("selectTime", true);
  }

  protected fSetTime() {
    this.date = "";
    this.value = "";
    this.refreshing = true;
    this.onRefresh();
  }

  protected onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.workerlist = [];
        this.refreshing = false;
      }
      api
        .userTemperatureRecordGetListByDate({
          pageNum: parseInt(this.workerlist.length / 10 + "") + 1,
          pageSize: 10,
          projectId: this.$store.state.project.projectinfo.projectId,
          userName: this.value,
          createDate: parseTime(this.date, "{y}-{m}-{d}"),
        })
        .then((res: any) => {
          this.workerlist = [...this.workerlist, ...res.data.records];
          this.loading = false;
          if (this.workerlist.length >= res.data.total) {
            this.finished = true;
          }
        });
    }, 500);
  }

  protected onRefresh() {
    this.finished = false;
    this.loading = true;
    this.onLoad();
  }

  protected goDetail(val: any) {
    this.$router.push({
      path: "/app/workerInfo",
      query: { id: val.userId, name: val.userName },
    });
  }
}
