
import { Options, Prop, Vue } from "vue-property-decorator";
import * as api from "@/api/epidemic";

@Options({})
export default class extends Vue {
  private refreshing = false;
  private loading = false;
  private finished = false;
  private recordslist: any[] = [];
  private show = false;
  private value = "";
  private flag = true;

  protected onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.recordslist = [];
        this.refreshing = false;
      }
      api
        .userTemperatureRecordPage({
          pageNum: parseInt(this.recordslist.length / 10 + "") + 1,
          pageSize: 10,
          projectId: this.$store.state.project.projectinfo.projectId,
        })
        .then((res: any) => {
          this.recordslist = [...this.recordslist, ...res.data.records];
          this.loading = false;
          if (this.recordslist.length >= res.data.total) {
            this.finished = true;
          }
        });
    }, 500);
  }

  protected onRefresh() {
    this.finished = false;
    this.loading = true;
    this.onLoad();
  }

  protected fGoAdd() {
    this.show = true;
  }

  protected closeDialog() {
    this.flag = true;
    this.value = "";
  }

  protected submit() {
    if (!this.value) {
      this.$toast.fail("请填写温度");
      this.flag = false;
    } else {
      var reg = /^\+?[1-9]\d{0,2}(\.\d*)?$/;
      if (reg.test(this.value) === true) {
        api
          .userTemperatureRecordSave({
            projectId: this.$store.state.project.projectinfo.projectId,
            temperature: this.value,
          })
          .then((res: any) => {
            this.flag = true;
            this.value = "";
            this.refreshing = true;
            this.onRefresh();
            this.$toast.success({
              message: "提交成功",
            });
          });
      } else {
        this.flag = false;
        this.$toast.fail({
          message: "请输入正确的体温",
        });
      }
    }
  }

  protected beforeClose(action: any) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.flag) {
          resolve(true);
        } else {
          // 拦截取消操作
          resolve(false);
        }
      }, 200);
    });
  }
}
