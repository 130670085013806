import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c1cea30"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "epidemic-container" }
const _hoisted_2 = { class: "first-tab" }
const _hoisted_3 = { class: "second-tab" }
const _hoisted_4 = { class: "third-tab" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!
  const _component_su_kang_code = _resolveComponent("su-kang-code")!
  const _component_van_tab = _resolveComponent("van-tab")!
  const _component_day_temperature = _resolveComponent("day-temperature")!
  const _component_worker_temperature = _resolveComponent("worker-temperature")!
  const _component_van_tabs = _resolveComponent("van-tabs")!
  const _component_van_datetime_picker = _resolveComponent("van-datetime-picker")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_nav_bar, {
      "left-arrow": "",
      title: _ctx.$route.meta.title,
      placeholder: "",
      onClickLeft: _ctx.goBack
    }, null, 8, ["title", "onClickLeft"]),
    _createVNode(_component_van_tabs, {
      active: _ctx.active,
      "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.active) = $event)),
      animated: "",
      swipeable: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_tab, { title: "苏康码" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_su_kang_code)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_van_tab, { title: "每日体温" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_day_temperature)
            ])
          ]),
          _: 1
        }),
        (_ctx.auth('/epidemic/userList'))
          ? (_openBlock(), _createBlock(_component_van_tab, {
              key: 0,
              title: "工人体温记录"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_worker_temperature, {
                    "select-date": _ctx.date,
                    onSelectTime: _ctx.selectTime
                  }, null, 8, ["select-date", "onSelectTime"])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["active"]),
    _createVNode(_component_van_popup, {
      show: _ctx.show,
      "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.show) = $event)),
      position: "bottom"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_datetime_picker, {
          modelValue: _ctx.currentDate,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentDate) = $event)),
          "min-date": _ctx.minDate,
          type: "date",
          onConfirm: _ctx.fConfirmDate,
          onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.show = false))
        }, null, 8, ["modelValue", "min-date", "onConfirm"])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}