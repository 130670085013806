
import { Options, Vue } from "vue-property-decorator";
import suKangCode from "./sukangcode.vue";
import dayTemperature from "./daytemperature.vue";
import workerTemperature from "./workertemperature.vue";

@Options({
  components: {
    suKangCode,
    dayTemperature,
    workerTemperature,
  },
})
export default class extends Vue {
  private active: any = "";
  private show = false;
  private date: Date | string = "";
  private currentDate = new Date();
  private minDate = new Date(2020, 0, 1);

  selectTime(val: any) {
    this.show = val;
  }

  mounted() {
    this.active = this.$store.state.project.active;
  }

  protected fConfirmDate(d: Date) {
    this.date = d;
    this.show = false;
  }

  protected goBack() {
    this.$router.push("/");
  }
}
