
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/epidemic";
import File from "@/api/file/index.ts";
import { parseTime } from "@/utils";

@Options({})
export default class extends Vue {
  private uploader1: any[] = [];
  private uploader2: any[] = [];
  private flag = true;
  private flag1 = true;
  // 上传的锡康码
  private file1 = "";
  // 上传的14天轨迹图
  private file2 = "";

  mounted() {
    api
      .userTrackRecordGetByProjectId(
        this.$store.state.project.projectinfo.projectId
      )
      .then((response: any) => {
        if (response.data) {
          var createDate = response.data.createDate;
          var startdate = parseTime(new Date(createDate), "{y}-{m}-{d}");
          var enddate = parseTime(new Date(), "{y}-{m}-{d}");
          var startdate1 = new Date(startdate as any);
          var enddate2 = new Date(enddate as any);
          var date3 = enddate2.getTime() - startdate1.getTime();

          var days = Math.floor(date3 / (24 * 3600 * 1000));

          if (days > 14) {
            this.$toast.fail({
              duration: 2500,
              forbidClick: true,
              message: "已过14天有效期，请重新上传",
            });
            this.flag1 = false;
          }
          if (response.data != null && days <= 14) {
            this.flag = false;
            var obj1 = { url: response.data.healthCodeUrl, status: "done" };
            var obj2 = {
              url: response.data.trackUrl,
              status: "done",
            };
            this.file1 = response.data.healthCodeUrl;
            this.file2 = response.data.trackUrl;
            this.uploader1.unshift(obj1);
            this.uploader2.unshift(obj2);
          }
        }
      });
  }

  protected afterRead1(files: any) {
    if (this.uploader1.length === 2) {
      this.uploader1 = [this.uploader1[1]];
    }
    let filearr = null;
    if (!Array.isArray(files)) {
      filearr = [files];
    } else {
      filearr = [...files];
    }
    filearr.map((file) => {
      file.status = "uploading";
      file.message = "上传中...";
      File.uploadFile("epidemic/", file.file.name, file.file, true).then(
        (res: any) => {
          file.status = "done";
          file.id = res.data.id;
          this.file1 = res.data.url;
        }
      );
    });
  }

  protected afterRead2(files: any) {
    if (this.uploader2.length === 2) {
      this.uploader2 = [this.uploader2[1]];
    }
    let filearr = null;
    if (!Array.isArray(files)) {
      filearr = [files];
    } else {
      filearr = [...files];
    }
    filearr.map((file) => {
      file.status = "uploading";
      file.message = "上传中...";
      File.uploadFile("epidemic/", file.file.name, file.file, true).then(
        (res) => {
          file.status = "done";
          file.id = res.data.id;
          this.file2 = res.data.url;
        }
      );
    });
  }

  // 删除健康码
  protected deleteUrlHealth() {
    this.file1 = "";
  }
  // 删除14天轨迹图
  protected deleteUrlTrackUrl() {
    this.file2 = "";
  }

  onSubmit() {
    if (this.file1 && this.file2) {
      api
        .userTrackRecordSave({
          projectId: this.$store.state.project.projectinfo.projectId,
          healthCodeUrl: this.file1,
          trackUrl: this.file2,
        })
        .then((res: any) => {
          if (res.code === 0) {
            this.$toast.success({
              message: this.flag ? "提交成功" : "更改成功",
            });
            this.flag = false;
            this.flag1 = true;
          } else {
            this.$toast.fail({
              message: this.flag ? "提交失败" : "更改失败",
            });
          }
        });
    } else {
      if (!this.file1 && !this.file2) {
        this.$toast.fail({
          message: "请上传健康码和轨迹图",
        });
      } else if (!this.file1) {
        this.$toast.fail({
          message: "请上传健康码",
        });
      } else {
        this.$toast.fail({
          message: "请上传轨迹图",
        });
      }
    }
  }
}
